import "./bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/css/sass.css";
import "./Assets/css/layouts.css";
import "./Assets/css/main.css";
import "./Assets/css/responsive.css";

import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { createInertiaApp, router } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { CreditCardContextProvider } from "./Pages/Context/CreditCardContext";
import { TwoDCreditCardContextProvider } from "./Pages/Context/TwoDCreditCardContext";



const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "";

router.on("success", (event) => {
    let isAuthenticated = event.detail.page.props.auth.user !== null;
    window.localStorage.setItem("isAuthenticated", isAuthenticated);
});

// Prevent recently logged out users from going back
window.addEventListener("popstate", (event) => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
        event.stopImmediatePropagation();
        router.visit("/login", { replace: true });
    }

    if (window.localStorage.getItem("isAuthenticated") === "true") {
        event.stopImmediatePropagation();
        router.reload();
    }
});

Echo.private(`messenger`).listen("MessageSend", (e) => {
    console.log("MessageSend", e);
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/*.jsx")
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(
            <StrictMode>
                {/* <CreditCardContextProvider>
                    <App {...props} />
                </CreditCardContextProvider> */}
                <TwoDCreditCardContextProvider>
                    <App {...props} />
                </TwoDCreditCardContextProvider>
            </StrictMode>
        );
    },
    progress: {
        color: "#ece40cec",
        showSpinner: true,
    },
});
