import React, { useState, useEffect, createContext } from "react";
import { useForm, router } from "@inertiajs/react";

const TwoDCreditCardContext = createContext();

const TwoDCreditCardContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const { data, setData, post, processing, errors, reset, setError } =
        useForm({
            first_name: "",
            last_name: "",
            phone_no: "",
            email: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zip: "",
            country_code: "",
            customer_order_id: "",
            amount: "",
            currency: "",
            card_no: "",
            cvvNumber: "",
            ccExpiryMonth: "",
            ccExpiryYear: "",
        });


    // card number and expiration validation
    useEffect(() => {
        setError({
            card_no: "",
            cvvNumber: "",
            ccExpiryMonth: "",
            ccExpiryYear: "",
        });

        // card number validation
        if (data.card_no.length < 16 && data.card_no.length > 0) {
            setError("card_no", "Card number must be 16 characters");
        }

        // CVV validation only number & 3 characters
        if (isNaN(data.cvvNumber)) {
            setError("cvvNumber", "CVV must be a number");
        }
        if (data.cvvNumber.length < 3 && data.cvvNumber.length > 0) {
            setError("cvvNumber", "CVV must be 3 characters");
        }

        // expiration month validation
        if (data.ccExpiryMonth.length < 2 && data.ccExpiryMonth.length > 0) {
            setError("ccExpiryMonth", "Month must be 2 characters");
        }

        // expiration year validation
        if (data.ccExpiryYear.length < 4 && data.ccExpiryYear.length > 0) {
            setError("ccExpiryYear", "Year must be 4 characters");
        }
    }, [data.card_no, data.cvvNumbercvvNumber, data.ccExpiryMonth, data.ccExpiryYear]);

    // reset form
    const resetForm = () => {
        reset();
    };

    // value for context
    const value = {
        data,
        setData,
        post,
        processing,
        errors,
        reset,
        setError,
        resetForm,
        loading,
        setLoading,
    };

    return (
        <TwoDCreditCardContext.Provider value={value}>
            {children}
        </TwoDCreditCardContext.Provider>
    );
};

export { TwoDCreditCardContext, TwoDCreditCardContextProvider };

// for (const key in c) {
//     if (Object.prototype.hasOwnProperty.call(c, key)) {
//         const element = c[key];
            // document.querySelector(`[name="${key}"]`).value = element
//     }
// }