import React, { useState, useEffect, createContext } from "react";
import { useForm, router } from "@inertiajs/react";

const CreditCardContexts = createContext();

const CreditCardContextProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [country, setCountry] = useState([]);
    const [loading, setLoading] = useState(false);
    const { data, setData, post, processing, errors, reset, setError } =
        useForm({
            currency: "",
            merchantId: "",
            app_secret: "",
            amount: "",
            description: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            address: "",
            country: "",
            country_id: "",
            state: "",
            postalCode: "",
            card_number: "",
            CVV: "",
            cctype: "",
            ccname: "",
            expirationMonth: "",
            expirationYear: "",
        });

    // countries initial partial load
    const countries = () => {
        router.reload({
            only: ["countries"],
            onBefore: () => {
                setLoading(true);
            },
            onSuccess: (page) => {
                setCountry(page.props.countries);
                setLoading(false);
            },
        });
    };

    // states partial load
    const filteredStates = country.find((c) => c.id == data.country_id)?.states;

    // show form
    useEffect(() => {
        setShow(false);
        setError({
            currency: "",
            description: "",
            amount: "",
        });
        if (
            data.currency &&
            data.amount &&
            data.description &&
            data.currency.length === 3
        ) {
            setShow(true);
        }

        // description validation

        // description only characters validation
        if (data.description.length < 5 && data.description.length > 0) {
            setError(
                "description",
                "Description must be at least 5 characters"
            );
            setShow(false);
        }

        // amount only number validation
        if (isNaN(data.amount)) {
            setError("amount", "Amount must be a number");
            setShow(false);
        }

        // currency validation
        if (data.currency.length > 3) {
            setError("currency", "Currency must be 3 characters");
            setShow(false);
        }
    }, [data.amount, data.currency, data.description]);

    // card number validation
    useEffect(() => {
        setError({
            card_number: "",
            CVV: "",
            expirationMonth: "",
            expirationYear: "",
        });
        // card number validation
        if (data.card_number.length < 16 && data.card_number.length > 0) {
            setError("card_number", "Card number must be 16 characters");
        }

        // cvv validation only number & 3 characters
        if (isNaN(data.CVV)) {
            setError("CVV", "CVV must be a number");
        }
        if (data.CVV.length < 3 && data.CVV.length > 0) {
            setError("CVV", "CVV must be 3 characters");
        }

        // expiration month only 2 number validation
        if (
            data.expirationMonth.length < 2 &&
            data.expirationMonth.length > 0
        ) {
            setError("expirationMonth", "Month must be 2 characters");
        }

        // expiration year validation
        if (data.expirationYear.length < 4 && data.expirationYear.length > 0) {
            setError("expirationYear", "Year must be 4 characters");
        }
    }, [data.card_number, data.CVV, data.expirationMonth, data.expirationYear]);

    // reset formCreditCardContexts
    const resetForm = () => {
        reset();
        setShow(false);
    };

    // value for context
    const value = {
        data,
        setData,
        post,
        processing,
        errors,
        reset,
        setError,
        resetForm,
        show,
        setShow,
        country,
        setCountry,
        loading,
        setLoading,
        countries,
        filteredStates,
    };

    return (
        <CreditCardContexts.Provider value={value}>
            {children}
        </CreditCardContexts.Provider>
    );
};

export { CreditCardContexts, CreditCardContextProvider };
